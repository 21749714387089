import React, { useState, useEffect, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Loader,
  Dimmer,
  Search,
  Image,
  Modal,
} from "semantic-ui-react";
// Mui
import { Skeleton } from "@material-ui/lab";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import { FormattedMessage } from "react-intl";
import moment from "moment";

// UI
import CardDoctor from "./CardDoctor";
import CardAllergyWarning from "./CardAllergyWarning";
import TabMenu from "./TabMenu";
import SnackMessage from "../clinic/SnackMessage";
import DrugDetail from "../medication/DrugDetail";
import ModalOffMedication from "../medication/ModalOffMedicationAll";

// Styles
import "./HistoryCSS.scss";
import { Dialog, Slide } from "@material-ui/core";
import CONFIG from "config/config";

//type
import { medicalRecordItemType, MedAllergyType } from"../MobHistoryinterface"

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
  } as CSSProperties,
  flexcenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10px 0px",
  } as CSSProperties,
  card: {
    width: "100%",
    height: "120px",
    borderRadius: "10px",
    padding: "15px",
  } as CSSProperties,
  title: {
    fontFamily: "Montserrat, Noto Sans Thai",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  text_black_16: {
    fontFamily: "Montserrat, Noto Sans Thai",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.font_black,
  } as CSSProperties,
  text_DarkGray_14: {
    fontFamily: "Montserrat, Noto Sans Thai",
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
  } as CSSProperties,
};

type MainHistoryProps = {
  onEvent: (e: any) => any;
  medicalRecordItem?: medicalRecordItemType[];
  selectedHospital?: any;
  allergyList?: any;
  medAllergyList?: MedAllergyType[];
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  language?: "en-US" | "th-TH";
  currentMedication?: any[];
  implantList?: any;
  loadingUpdateCurrent?: boolean;
  isCurrentMed: boolean;
};

const TransitionDialog = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const MainHistory: React.FunctionComponent<MainHistoryProps> = (props) => {
  const [cardTab, setCardTab] = useState<string>("Allergy");
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [medicationDetail, setMedicationDetail] = useState<any>(null);
  const [searchBox, setSearchBox] = useState<string>("");
  const [keyWord, setkeyWord] = useState<string>("");
  const [thirtyDays, setThirtyDays] = useState<medicalRecordItemType[] | undefined>([]);
  const [threeYears, setThreeYears] = useState<medicalRecordItemType[] | undefined>([]);
  const [loading, setLoading] = useState<boolean | undefined>(true);
  const [openDrugDetail, setOpenDrugDetail] = React.useState<boolean>(false);
  const [openOffAllDrug, setOpenOffAllDrug] = React.useState<any>({
    typeON: false,
    open: false,
  });

  let history = useHistory();

  const mobImplant = [
    { implant: "Upper Limb Prosthsesis", hospital: "Bangkok Hospital Siriroj" },
    { implant: "Cochlear implant", hospital: "Bangkok Hospital Headquarters" },
    {
      implant: "Procedure: Phaccemulsification Intraaccular Lens Right Eye",
      hospital: "Bangkok Hospital Headquarters",
    },
  ];

  useEffect(() => {
    const hospital = props.selectedHospital;
    if (hospital) {
      props.onEvent({
        message: "HandleMedicalRecord",
        params: { hospital: props.selectedHospital?.code },
      });

      if (CONFIG.ENABLE_ALLERGY) {
          props.onEvent({
            message: "GetMedAllergy",
            params: { hospital: hospital.code, name: hospital.name },
          });

        if (CONFIG.ENABLE_IMPLANT) {
          props.onEvent({
            message: "GetImplantData",
            params: { hospitalName: hospital.name, hospital: hospital.code },
          });
        }
      }
    }
  }, [props.selectedHospital]);

  useEffect(() => {
    if (props.medicalRecordItem) {
      const newthityDay: medicalRecordItemType[] = [];
      const newthreeYear: medicalRecordItemType[] = [];
      props.medicalRecordItem.forEach((item: medicalRecordItemType) => {
        const threeYear = moment().subtract(3, "years").format("YYYY-MM-DD");
        const thityDay = moment().subtract(30, "days").format("YYYY-MM-DD");
        const date = moment(item.started).format("YYYY-MM-DD");
        if (moment(date).isAfter(thityDay)) {
          newthityDay.push({ ...item });
        } else if (moment(date).isAfter(threeYear)) {
          newthreeYear.push({ ...item });
        }
      });

      if (newthityDay.length > 0) {
        setThirtyDays(newthityDay);
      } else {
        setThirtyDays(undefined);
      }

      if (newthreeYear.length > 0) {
        setThreeYears(newthreeYear);
      } else {
        setThreeYears(undefined);
      }
    }
  }, [props.medicalRecordItem]);

  const handleSearchDoctor = (value: any) => {
    setSearchBox(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setkeyWord(value);
      }, 800)
    );
  };

  const handleChangeCardTab = (cardTab: string) => {
    setCardTab(cardTab);
  };

  const closeSnackMessage = () => {
    props.onEvent({ message: "closeSnackMessage" });
  };

  const handleOpenDrugDetail = (item: any) => {
    setOpenDrugDetail(true);
    setMedicationDetail(item);
  };

  const handleCloseDrugDetail = () => {
    setOpenDrugDetail(false);
  };

  const handleClose = () => {
    setOpenOffAllDrug({ ...openOffAllDrug, open: false });
  };

  const handlefilter = (data: any, keyWord: string) => {
    if (keyWord !== "") {
      return data
        .filter((item: any) => item?.doctor_details?.full_name != null)
        .filter(
          (item: any) =>
            item?.doctor_details?.full_name
              .toLowerCase()
              .search(keyWord.toLowerCase()) !== -1
        );
    } else {
      return data;
    }
  };

  return (
    <div className="Mainhistory" style={{ ...styles.parentbox }}>
      <Dimmer
        inverted
        active={props.loadingUpdateCurrent}
        style={{ height: "100%", position: "fixed" }}
      >
        <Loader>Loading</Loader>
      </Dimmer>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={closeSnackMessage}
        error={props.errorMessage}
        success={props.successMessage}
      />
      {/* render UI MainHistory  */}
      <div style={{ margin: " 20px 0px" }}>
        <div style={styles.title}> Personal Health Record </div>
        <Divider
          style={{
            background: "red",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          variant="middle"
        />
      </div>
      {/* bottom select tab  */}
      {CONFIG.ENABLE_ALLERGY && (
        <>
          <TabMenu
            tab={cardTab}
            leftTitle="Allergy"
            rightTitle="Implant"
            rightDisabled={!CONFIG.ENABLE_IMPLANT}
            // config
            paddingStyle="0 15px"
            marginStyle="0 0 8px"
            // callback
            onChange={handleChangeCardTab}
          />
          {/* Swipe Carddetail  */}
          <div style={{ width: "100%" }}>
            {cardTab === "Allergy" && (
              <>
                <CardAllergyWarning
                  history={history}
                  allergyList={props.medAllergyList || []}
                  mode="swipeable"
                  detailField={["detail", "name_generic", "name_trade"]}
                  hospitalField="hospital"
                  onEvent={props.onEvent}
                  href={`/Allergy-card/?app=MobHistory`}
                />
              </>
            )}
            {cardTab === "Implant" && (
              <CardAllergyWarning
                history={history}
                allergyList={props.implantList || []}
                mode="swipeable"
                isImplant={true}
                detailField="implant"
                hospitalField="hospital"
                type={"implant"}
                onEvent={props.onEvent}
                href={`/Implant-Card/?app=MobHistory`}
              />
            )}
          </div>
        </>
      )}
      {props.loadingStatus ? (
        <>
          {/* render Skeleton  */}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "0px 20px",
            }}
          >
            <div style={{ width: "20%" }}>
              {" "}
              <Skeleton />
            </div>
            <div style={{ width: "30%" }}>
              <Skeleton />
            </div>
          </div>
          <div style={{ width: "100%", padding: "0px 20px" }}>
            <Skeleton height="100%" width="100%" />
          </div>
          {new Array(4).fill("").map((item: any) => (
            <div
              key={item}
              style={{
                borderRadius: "15px",
                height: "140px",
                width: "90%",
                margin: "0px",
              }}
            >
              <Skeleton height={150} />
            </div>
          ))}
        </>
      ) : (
        <>
          {/* section thirtyDays */}
          {thirtyDays == null && threeYears == null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Image
                    src={"/images/icon_no_history.png"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <FormattedMessage id={"bplusClinicKey941"} />
                </div>
              </div>
            </div>
          ) : (
            <>
              {thirtyDays ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                    }}
                  >
                    <div style={{ ...styles.text_black_16 }}>{"History"}</div>
                    <div style={{ ...styles.text_DarkGray_14 }}>
                      {"Last 30 days"}
                    </div>
                  </div>
                  <div style={styles.flexcenter}>
                    <div
                      className="history_search"
                      style={{ width: "100%", padding: "0 20px", fontFamily: "Montserrat, Noto Sans Thai", }}
                    >
                      <Search
                        input={{ icon: "search", iconPosition: "left" }}
                        onSearchChange={(e, { value }) => {
                          handleSearchDoctor(value);
                        }}
                        placeholder="Search ..."
                        showNoResults={false}
                        value={searchBox}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    {
                      <CardDoctor
                        medicalRecord={handlefilter(thirtyDays, keyWord)}
                        language={props.language}
                        selectedHospital={props.selectedHospital}
                        handleOpenDrugDetail={handleOpenDrugDetail}
                        currentMedication={props.currentMedication}
                        onEvent={props.onEvent}
                        isCurrentMed={props.isCurrentMed}
                      />
                    }
                  </div>
                </>
              ) : (
                ""
              )}

              {/* section threeYears */}

              {threeYears ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                    }}
                  >
                    <div style={{ ...styles.text_black_16 }}>{"History"}</div>
                    <div style={{ ...styles.text_DarkGray_14 }}>
                      {"Last 3 years"}
                    </div>
                  </div>

                  {!thirtyDays ? (
                    <>
                      <div style={styles.flexcenter}>
                        <div
                          className="history_search"
                          style={{ width: "100%", padding: "0 20px" }}
                        >
                          <Search
                            input={{ icon: "search", iconPosition: "left" }}
                            onSearchChange={(e, { value }) => {
                              handleSearchDoctor(value);
                            }}
                            placeholder="Search ..."
                            showNoResults={false}
                            value={searchBox}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div style={{ width: "100%" }}>
                    {
                      <CardDoctor
                        medicalRecord={handlefilter(threeYears, keyWord)}
                        language={props.language}
                        selectedHospital={props.selectedHospital}
                        handleOpenDrugDetail={handleOpenDrugDetail}
                        currentMedication={props.currentMedication}
                        onEvent={props.onEvent}
                        isCurrentMed={props.isCurrentMed}
                      />
                    }
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}

      {/* Popup off all medication */}
      <Dialog
        fullScreen
        open={openDrugDetail}
        // @ts-ignore
        TransitionComponent={TransitionDialog}
      >
        <div style={{ width: "100%" }}>
          <DrugDetail
            onClose={handleCloseDrugDetail}
            onEvent={props.onEvent}
            medicationItem={medicationDetail}
          />
        </div>
      </Dialog>

      {/* Popup off all medication */}
      <Modal
        onClose={() => handleClose}
        open={openOffAllDrug.open}
        style={{
          borderRadius: "5px",
        }}
      >
        <ModalOffMedication
          typeON={openOffAllDrug.typeON}
          visit={true}
          decline={handleClose}
          accept={handleClose}
        />
      </Modal>
    </div>
  );
};

export default React.memo(MainHistory);

const MobHistory = [
  {
    id: "123",
    created: "123",
    edited: "123",
    pk: "123",
    episode: "123",
    started: "12/10/2022",
    examination_type: "123",
    doctor_name: "123",
    patient_name: "123",
    patient_reg_type: "123",
    division_name: "123",
    division_short_name: "123",
    division_code: "123",
    status: "123",
    hn: "123",
    status_name: "123",
    status_value: "123",
    medical_record: "123",
    checkout_cause: "123",
    location: "123",
    location_id: "123",
    go_to: "123",
    appoint_time: "123",
    doctor: "123",
    coverage: "123",
    allowed_actions: "123",
    general_type: "123",
    zone: "123",
    diag_text: "123",
    chief_complaint: "123",
    present_illness: "123",
    drug_order: [
      {
        name: "Dr. PAT PHATRAPAKDEE",
        department: "(BHQ) Diabetes and Endocrine...",
        date: "09/11/2022/10:30",
        detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
        status: undefined,
        qty: "10 Tablet",
        exp: "10/12/2025",
      },
      {
        name: "Dr. PAT PHATRAPAKDEE",
        department: "(BHQ) Diabetes and Endocrine...",
        date: "10/11/2022/10:30",
        detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
        status: true,
        qty: "10 Tablet",
        exp: "10/12/2025",
      },
      {
        name: "Dr. PAT PHATRAPAKDEE",
        department: "(BHQ) Diabetes and Endocrine...",
        date: "11/11/2022/10:30",
        detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
        status: false,
        qty: "10 Tablet",
        exp: "10/12/2025",
      },
    ],
    lab_order: [],
    vital_sign: [],
    number: "123",
    type: "123",
    remark: "123",
    note: "123",
    drug_allergy: "123",
    other_allergy: "123",
    edit_user: "123",
    patient: "123",
    division: "123",
    triage: "123",
    predischarge_condition: 123,
    discharge_status: 123,
    cleaning: "123",
    doctor_image: "123",
    department_name: "123",
    doctor_details: null,
  },
];
