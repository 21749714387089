import React from 'react';
import PropTypes from 'prop-types';
import {
  Message,
  MessageHeader,
} from 'semantic-ui-react';
import _ from '../../compat/lodashplus';
import { useIntl } from "react-intl";

class ErrorMessage extends React.PureComponent <{error?: any, header?: any, style?: any, success?: any; warning?: any}, {} > {
  static propTypes = {
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
  }

  render() {
    const {
      error,
      style,
      success,
      warning,
      header
    } = this.props;

    const errorElement = this.getErrorComponent(error);
    const successElement = this.getErrorComponent(success);
    const warningElement = this.getErrorComponent(warning);

    if (errorElement) {
      return (
        <Message style={style} visible={!!error} error>
          {header && <MessageHeader>{header}</MessageHeader>}
          {errorElement}
        </Message>
      );
    } else if (successElement) {
      return (
        <Message style={style} visible={!!success} success>
          {header && <MessageHeader>{header}</MessageHeader>}
          {successElement}
        </Message>
      );
    } else if (warningElement) {
      return (
        <Message style={style} visible={!!warning} warning>
          {header && <MessageHeader>{header}</MessageHeader>}
          {warningElement}
        </Message>
      );
    } else {
      return null;
    }
  }

  getErrorComponent(errors:any = {}){
    let counter = 0;
    if (_.has(errors, 'message')
      && _.has(errors, 'code')
      && _.has(errors, 'action')
      && _.has(errors, 'status')
    ) {
      errors = errors.message;
    }

    function renderErrorListWithLabel(label:any, error:any) {
      return (
        <Message.Item key={counter++}>
          <b>{label}</b>
          <Message.List>
            {error}
          </Message.List>
        </Message.Item>
      );
    }
    function renderErrorString(error:any){
      if (error.includes('Installed Applications:')) {
        return '';
      } else {
        return error === " " ? (
          <></>
        ) : (
          <Message.Item key={counter++}>
            <div dangerouslySetInnerHTML={{ __html: error }} />
          </Message.Item>
        );
      }
    }
    function dispatchError(error:any){
      let result:any = '';
      if (!error) {
        // do nothing
      } else if (typeof error === 'string') {
        result = renderErrorString(error);
      } else if (error instanceof Array) {
        result = dispatchErrorArray(error);
      } else if (typeof error === 'object') {
        result = dispatchErrorObject(error);
      }
      return result;
    }
    function dispatchErrorArray(errors:any){
      const result:any[] = []
      if (errors.length === 0) {
        return '';
      } else if (_.filter(errors , (x:any) => typeof x === 'string').length === errors.length) {
        return _.map(errors, dispatchError);
      }

      _.each(errors, (item:any, i:any) => {
        let error = dispatchError(item);
        if (error) {
          result.push(renderErrorListWithLabel(i + 1, error));
        }
      })
      return result;
    }
    function dispatchErrorObject(errors:any){
      const keys = Object.keys(errors);
      if (keys.length === 0) {
        return '';
      }
      const result:any[] = [];
      keys.forEach(function(key){
        let error = null;
        if (key === 'action' && typeof errors.action === 'object' && 'message' in errors.action) {
          error = dispatchError(errors.action.message);
        } else {
          error = dispatchError(errors[key]);
        }
        if (!error) {
          // do nothing
        } else if (key === '__all__' || key === 'non_field_errors') {
          result.push(error);
        } else {
          result.push(renderErrorListWithLabel(key, error));
        }
      });
      return result;
    }

    const result:any = dispatchError(errors);
    const isReactElement = React.isValidElement(result);
    const listOfElements = result instanceof Array && result.length;
    if (isReactElement || listOfElements) {
      return <Message.List bulleted='true'> {result} </Message.List>;
    } else {
      return null;
    }
  }
}

export default ErrorMessage;