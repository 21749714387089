import React from "react"
import AnalyticsController from "./AnalyticsController";
import * as AnalyticsI from "./AnalyticsInterface";
import SetProp from "react-lib/frameworks/SetProp";
import LoginForm from "./login/LoginForm";
import AnalyticsConfig from "./appConfig/Analytics.json";
import Cookies from "js-cookie";
import TopBarClinic from "./manage/TopBarClinic";
import { Input, Button } from "semantic-ui-react";
import TabFramework from "react-lib/frameworks/Tab";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

class Analytics extends React.Component<any, AnalyticsI.State> {
  
  controller: AnalyticsController;
  appConfig: any;
  
  constructor(props: any) {
    super(props);
    this.appConfig = AnalyticsConfig;
    this.state = { ...AnalyticsI.StateInitial  }
    this.controller = new AnalyticsController(
      () => { return this.state },
      (state: AnalyticsI.State, callback: any) => { 
        this.setState(state, callback) 
      },
      window
    )
    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} })
  }

  render = () => {
    return !this.state.loggedin ? (
      <LoginForm
          appConfig={this.appConfig}
          onEvent={this.controller.handleEvent}
        />
      ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}>
        <TopBarClinic
          appConfig={this.appConfig}
          onEvent={this.controller.handleEvent}
          setProp={this.controller.setProp}
          history={this.props.history}
          hideSearch={true}
        />
        <div style={{ padding: "10px" }}>
          <Input 
            style={{ width: "200px" }}
            placeholder="Start date: YYYY-MM-DD"
            value={this.state.start_date}
            onChange={(e: any, v: any) => {
              this.setState({ start_date: v.value });
            }}
          />
          <Input 
            style={{ width: "200px" }}
            placeholder="End date: YYYY-MM-DD"
            value={this.state.end_date}
            onChange={(e: any, v: any) => {
              this.setState({ end_date: v.value });
            }}
          />
          <Button color="blue"
            loading={this.state.downloading}
            onClick={(e: any) => {
              this.controller.handleEvent({
                message: "FetchReport",
                params: {}
              })
            }}>
            <FormattedMessage id="bplusClinicKey228" />
          </Button>
          <Button color="green"
            loading={this.state.downloadingCovid}
            onClick={(e: any) => {
              this.controller.handleEvent({
                message: "FetchCovid",
                params: {}
              })
            }}>
            <FormattedMessage id="bplusClinicKey134" />
          </Button>
        </div>
      </div>
    )
  }
  
}

export default Analytics;
