import { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
// CSS
import "../bplus-lib/history/MainHistory.scss"

// Library
import Cookies from "js-cookie";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import Fingerprint2 from "fingerprintjs2";
import {
  IntlProvider,
  // createIntlCache,
  // createIntl,
} from "react-intl";

// Frameworks
import SetProp from "react-lib/frameworks/SetProp";

// IsHealth
import { createNotificationSubscription } from "../react-lib/apps/IsHealth/Common/push-notifications";
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";

// Controller
import MobHistoryController from "./MobHistoryController";

// Interface
import * as MobHistoryI from "./MobHistoryinterface";

// UI
import CONFIG from "../config/config";

// Style
import "../css/MobAppointment.scss";

import HeaderBar from "../bplus-lib/medication/HeaderBar";
import SelectHospital from "./appointment/SelectHospital";
import MainHistory from "../bplus-lib/history/MainHistory";
import ImplantDetail from "../bplus-lib/history/ImplantDetail";
import AllergyDetail from "../bplus-lib/history/AllergyDetail";

import {
  Header,
  Image,
  Container,
  HeaderSubheader,
  HeaderContent,
} from "semantic-ui-react";

// Constant
const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

class MobHistory extends Component<any, MobHistoryI.State> {
  controller: MobHistoryController;

  constructor(props: any) {
    super(props);
    this.state = { ...MobHistoryI.StateInitial };
    this.controller = new MobHistoryController(
      () => {
        return this.state;
      },
      (state: MobHistoryI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    // Get login info from Mobile App
    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({
        message: "GetLoginInfo",
        params: {},
      });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    document.querySelector("body")?.classList.add("body-mob-appointment");

    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
      this.controller.handleEvent({
        message: "HandleGetUserWithPatientDetail",
        params: {},
      });
    }
  }

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      console.log("userProfile", userProfile)
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  handleGoBackAllergy = () => {
    this.props.history.goBack();

    this.controller.handleEvent({
      message: "HandleChangeAllergyTab",
      params: { reset: true },
    });
  };

  render = () => {
    // console.log(this.state);
    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div
          className="MobHistory"
          style={{ background: "white", height: "100vh" }}>
          <Switch>
            <Route
              path="/Select-Hospital"
              render={(props) => {
                return (
                  <div>
                    <SelectHospital
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      onClose={() => this.props.history.goBack()}
                      history={this.props.history}
                      selectedHospital={this.state.selectedHospital}
                      firstLoadingHospital={!this.state.loadingHospital}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/Implant-Card"
              render={(props) => {
                return (
                  <div>
                    <HeaderBar
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      setTitle={" "}
                      headerSx={{
                        background: `linear-gradient(90deg, #0147A3 0%, #0B87CD 100%) !important`,
                      }}
                      currentMob={"MobHistory"}
                      buttonLeftback={true}
                      hiddenLeft={true}
                      hiddenRight={true}
                      style={{ zIndex: 1003 }}
                    />
                    <div className="screen">
                      <div className="header_inner">
                        <Container className="backgroundImage">
                          <Image src="/images/History/history_bg1.png" />
                        </Container>
                        <div className="foregroundContent">
                          <p className="title-text-white">Implant Card</p>
                        </div>
                      </div>
                      <div style={{ position: "absolute", width: "100%" }}>
                        <div className="card">
                          <ImplantDetail
                            selectedHospital={this.state.selectedHospital}
                            patientDetail={this.state.patientDetail}
                            implantList={this.state.implantList}
                            selectedImplant={this.state.selectedImplant}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <Route
              path="/Allergy-Card"
              render={(props) => {
                return (
                  <div>
                    <HeaderBar
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      history={this.props.history}
                      setTitle={"Allergy and Warning"}
                      currentMob={"MobHistory"}
                      buttonLeftback={true}
                      hiddenLeft={true}
                      hiddenRight={true}
                      // callback
                      onGoBack={this.handleGoBackAllergy}
                    />
                    <AllergyDetail
                      // function
                      onEvent={this.controller.handleEvent}
                      // data
                      history={this.props.history}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      medAllergyList={this.state.medAllergyList}
                      firstLoadingAllergy={this.state.firstLoadingAllergy}
                      medAllergyGroup={this.state.medAllergyGroup}
                      allergyTab={this.state.allergyTab}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/bottom_navigation/"
              render={(props) => {
                return (
                  <div>
                    <HeaderBar
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      history={this.props.history}
                      currentMob={"MobHistory"}
                      hiddenLeft={true}
                      hiddenRight={true}
                    />
                    <MainHistory
                      onEvent={this.controller.handleEvent}
                      allergyList={this.state.allergyList}
                      implantList={this.state.implantList}
                      medicalRecordItem={this.state.medicalRecordItem}
                      loadingStatus={this.state.loadingStatus}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      medAllergyList={this.state.medAllergyList}
                      selectedHospital={this.state.selectedHospital}
                      language={this.state.language}
                      currentMedication={this.state.currentMedication}
                      loadingUpdateCurrent={this.state.loadingUpdateCurrent}
                      isCurrentMed={CONFIG.ENABLE_CURRENT_MEDICATION}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/"
              render={(props) => {
                return (
                  <div>
                    <HeaderBar
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      history={this.props.history}
                      currentMob={"MobHistory"}
                    />
                    <MainHistory
                      onEvent={this.controller.handleEvent}
                      allergyList={this.state.allergyList}
                      implantList={this.state.implantList}
                      medicalRecordItem={this.state.medicalRecordItem}
                      loadingStatus={this.state.loadingStatus}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      medAllergyList={this.state.medAllergyList}
                      selectedHospital={this.state.selectedHospital}
                      language={this.state.language}
                      currentMedication={this.state.currentMedication}
                      loadingUpdateCurrent={this.state.loadingUpdateCurrent}
                      isCurrentMed={CONFIG.ENABLE_CURRENT_MEDICATION}
                    />
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobHistory);
