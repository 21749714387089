import React, { CSSProperties } from "react";
// Mui
import Box from "@mui/material/Box";
import { makeStyles, Button as MuiButton } from "@material-ui/core";

type TabMenuProps = {
  tab?: string;
  leftTitle: string;
  rightTitle: string;
  // config
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  paddingStyle?: string;
  marginStyle?: string;
  // callback
  onChange: (value: string) => any;
};

// Use Styles
export const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  super_dark_gray: "#393939",
  blue_bdms: "var(--blue-bdms-color)",
  super_light_blue: "#CCDAED",
  background_button: "#F5F5F5",
  dark_grey: "#797878",
  super_light: "rgba(233, 233, 233, 1)",
};

export const useStyles = makeStyles(() => ({
  box_tabs: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    borderRadius: "5px",
    overflow: "hidden",
    backgroundColor: "#F5F5F5",
    "& > .MuiButton-root": {
      padding: "4px 8px",
    },
  },
}));

export const styles = {
  btn_tab: {
    fontFamily: "Montserrat, Noto Sans Thai",
    fontSize: "14px",
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#F5F5F5",
    color: COLORS.dark_grey,
    fontWeight: "500",
    boxShadow: "none",
    textTransform: "unset",
  } as CSSProperties,
};

const TabMenu: React.FC<TabMenuProps> = (props) => {
  const classes = useStyles();

  const handleChange = (tab: string) => () => {
    props.onChange(tab);
  };

  return (
    <div style={{ width: "100%", padding: props.paddingStyle ?? 0 }}>
      <Box
        className={classes.box_tabs}
        style={{ margin: props.marginStyle ?? "0 0 20px" }}
      >
        <MuiButton
          style={{
            ...styles.btn_tab,
            pointerEvents: "auto",
            ...(props.tab === props.leftTitle
              ? {
                  backgroundColor: COLORS.super_light_blue,
                  color: COLORS.blue_bdms,
                  fontWeight: "600",
                }
              : {}),
          }}
          disabled={props.leftDisabled}
          onClick={handleChange(props.leftTitle)}
        >
          {props.leftTitle}
        </MuiButton>
        <MuiButton
          style={{
            ...styles.btn_tab,
            pointerEvents: "auto",
            ...(props.tab === props.rightTitle
              ? {
                  backgroundColor: COLORS.super_light_blue,
                  color: COLORS.blue_bdms,
                  fontWeight: "600",
                }
              : {}),
          }}
          disabled={props.rightDisabled}
          onClick={handleChange(props.rightTitle)}
        >
          {props.rightTitle}
        </MuiButton>
      </Box>
    </div>
  );
};

export default React.memo(TabMenu);
