import WasmController from "../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// Interface
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobMedicationI from "./MobMedicationInterface";
import * as MobLABI from "./MobLABInterface";

// APIs
import V3MedicalRecordsView from "issara-sdk/apis/V3MedicalRecordsView_apps_PRX";
import V3AllerView from "issara-sdk/apis/V3AllergyView_apps_PRX";
import V3MedAllergy from "issara-sdk/apis/V3MedAllergy_apps_PRX";
import V3GetImplant from "issara-sdk/apis/V3GetImplant_apps_PRX";
import CurrentMedication from "../issara-sdk/apis/CurrentMedication_apps_PHR";
import UserWithPatientDetail from "issara-sdk/apis/UserWithPatientDetail_users";
import CONFIG from "../config/config";
import axios from "axios";

export type State = {
  // common
  loggedin?: boolean;
  platform?: string;
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  language?: "en-US" | "th-TH";
  // message
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  patientDetail?: any
  allergyList?: any;
  medicalRecordItem?: medicalRecordItemType[];
  currentMedication?: any[];
  loadingUpdateCurrent?: boolean;
  // State Allergy
  medAllergyList?: MedAllergyType[];
  implantList?: any;
  selectedImplant?: any;
  firstLoadingAllergy?: boolean;
  allergyTab?: AllergyTab;
  medAllergyGroup?: {
    allergy: any[];
    warning: any[];
  };
  // ----- END Allergy
} & SelectHospitalI.State;

export type MedAllergyType = {
  name_generic: string;
  name_trade: string;
  detail: string;
  type: string;
  probability: string;
  comment: string;
  testing: string;
  last_used: string;
};

export type medicalRecordItemType = {
  id?: number | string;
  started?: string;
  diag_text?: string;
  chief_complaint?: string;
  present_illness?: string;
  drug_order?: DrugOrderType[];
  lab_order?: LabOrderType[];
  division_name?: string;
  doctor_details?: DoctorType;
  created?: string;
  edited?: string;
  active?: boolean;
  checkout_time?: string;
  checkout_cause?: string;
  edit_user?: string;
  encounter?: string;
  doctor?: number;
  Diagnosis?: string;
  PatientInstruction?: string;
  hospital_name?: string;
  hospital_code?: number | string;
  consult_doctors?: ConsultDoctorType[];
};

type AllergyTab = "Allergy" | "Warning";

// const language = ;  // language without region code
export const StateInitial: State = {
  userId: "",
  loggedin: false,
  platform: "",
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  loadingStatus: true,
  errorMessage: null,
  successMessage: null,
  allergyList: null,
  implantList: null,
  selectedImplant: null,
  firstLoadingAllergy: false,
  currentMedication: [],
  loadingUpdateCurrent: false,
  ...SelectHospitalI.StateInitial,
};

export type ConsultDoctorType = {
  division?: string;
  arrived_dt?: string;
  doctor?: DoctorType;
  division_name?: string
  main?: boolean
};

export type DoctorType = {
  id?: number;
  code?: string;
  pre_name?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  is_doctor?: boolean;
  image?: string;
  specialties?: string[];
};

export type LabOrderType = {
  id?: number;
  created?: string;
  edited?: string;
  pk?: any;
  _id?: any;
  urgency?: string;
  lab_code?: string;
  name?: string;
  lab_division?: string;
  specimen_name?: string;
  is_blood?: boolean;
  color?: any;
  received?: boolean;
  printable?: boolean;
  is_printed?: boolean;
  status?: string;
  status_name?: string;
  status_label?: string;
  patient?: number;
  lab_type_label?: string;
  allowed_actions?: any;
  children?: LabOrderType[];
  history_results?: HistoryResultsType[];
  specimen_time?: any;
  code?: string;
  quantity?: number;
  note?: string;
  is_child?: any;
  edit_user?: any;
  specimen_container?: any;
  order?: string;
  product?: any;
  specimen?: any;
  unit?: any;
  doctor_code?: string;
};

export type DrugOrderType = {
  id?: string;
  name?: string;
  note?: Record<string, string>;
  image?: string;
  count?: string;
  unit?: string;
  active?: boolean;
  dosage?: string;
  dose?: string;
  dosage_image?: string;
  unit_image?: string;
  route?: number;
  route_description?: string;
  route_description_th?: string;
  route_display_seq?: number;
  route_tc?: string;
  route_tc_th?: string;
  route_image?: string;
  doctor?: string;
  order_date?: string;
  extra?: ExtraType;
  med_detail?: string;
  med_used_for?: string;
  med_need_before?: string;
  how_to_use_med?: string;
  miss_dose?: string;
  med_side_effect?: string;
  med_emer_warning?: string;
  med_store?: string;
  indication?: string;
  encounter_number?: string;
  prescription_code?: string;
  pi_my_med_url?: string;
  media_link?: string;
  is_save_drug?: boolean;
  hospital_name?: string;
  hospital_code?: number | string;
  doctor_code?: string;
};

export type ExtraType = {
  dispense_type?: string;
  before?: boolean;
  with?: boolean;
  after?: boolean;
  morning?: boolean;
  afternoon?: boolean;
  evening?: boolean;
  bedtime?: boolean;
  other?: boolean;
  is_prn?: boolean;
};

// lab_results 
// history_results
export type HistoryResultsType = {
  id?: number | string;
  is_numeric?: boolean;
  created?: string;
  edited?: string;
  value?: string;
  value_type?: number;
  comment?: string;
  reported_datetime?: string;
  ref_value_min?: number;
  ref_value_max?: number;
  ref_value_txt?: string;
  critical_flag?: string;
  authorized?: boolean;
  flag_result?: number;
  edit_user?: any;
  order_item?: any;
  previous_result?: any;
  report_user?: any;
  authorize_user?: any;
  visit_number?: string;
  encounter_number?: string;
  specimen_collect_time?: string;
  visit_date?: string;
  display_seq?: string;
  doctor_code?: string;
};

export type Event =
  | { message: "GetLoginInfo"; params: {} }
  | { message: "GetImplantData"; params: { hospital: any } }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "GetListHospital"; params: {} }
  | { message: "closeSnackMessage" }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "HandleAllergy"; params: {} }
  | { message: "HandleGetUserWithPatientDetail"; params: {} }
  | {
    message: "HandleMedicalRecord";
    params: { hospital: any };
  }
  | { message: "GetMedAllergy"; params: { hospital?: string; name?: string } }
  | {
    message: "HandleChangeAllergyTab";
    params: { tab?: AllergyTab; reset?: boolean };
  }
  | {
    message: "handleUpdateCurrentMed";
    params: { encounter: any; drug_id: any; state: any };
  }
  | {
    message: "SelectedImplant";
    params: { item?: any };
  }
  | MobMedicationI.Event
  | MobLABI.Event
  | SelectHospitalI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  console.log("MobHistory DidMount: ");

  const [_, error] = await SelectHospitalI.SelectedInitialHospital(
    controller,
    params
  );

  controller.setState({
    errorMessage: error ? error : null,
    successMessage: null,
  });
};

export const DidUpdate: Handler = async (controller, params) => { };

export const closeSnackMessage: Handler = async (controller) => {
  controller.setState({ errorMessage: null, successMessage: null });
};

export const GetListHospital: Handler = async (controller, params) => {
  console.log("GetListHospital");
  const [res, error] = await SelectHospitalI.GetHospital(controller, params);
  console.log("GetListHospital", res);
  controller.setState({
    hospitalList: res?.items || [],
    errorMessage: error ? error : null,
    successMessage: null,
  });
};

export const HandleGetUserWithPatientDetail: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (Object.keys(state.patientDetail || {})?.[0]) {
    return;
  }

  const [res, error] = await UserWithPatientDetail.retrieve({
    pk: params.userId || Cookies.get("userId"),
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });


  controller.setState({ patientDetail: res || {} });
};

export const HandleAllergy: Handler = async (controller) => {
  const [res, error] = await V3AllerView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      hospital: "all",
    },
  });

  controller.setState({
    allergyList: res?.items || [],
    errorMessage: error ? error : null,
    successMessage: null,
  });
};

export const HandleMedicalRecord: Handler = async (controller, params) => {
  controller.setState({
    loadingStatus: true,
  });

  const [res, error] = await V3MedicalRecordsView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
  });

  const [resCurrent, errorCurrent] = await CurrentMedication.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
  });

  console.log("saika ;) resCurrent ", resCurrent);

  controller.setState({
    medicalRecordItem: res?.results || [],
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    currentMedication: resCurrent || [],
  });
};

export const handleUpdateCurrentMed: Handler = async (controller, params) => {
  controller.setState({
    loadingUpdateCurrent: true,
  });

  const [resCurrent, errorCurrent] = await CurrentMedication.post({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
    data: params,
  });

  controller.setState({
    currentMedication: resCurrent,
    loadingUpdateCurrent: false,
  });
};

/* ------------------------------------------------------ */
/*                      API ALLERGY                     */
/* ------------------------------------------------------ */
export const GetMedAllergy: Handler = async (controller, params) => {

  if (params.hospital !== "1") {
    controller.setState({
      medAllergyList: [],
      firstLoadingAllergy: true,
    });
    return;
  }

  const result = await V3MedAllergy.get({
    apiToken: controller.cookies.get("apiToken"),
    params: { hospital: params.hospital },
  });

  const items: any[] = (result[0] || []).map((item: any) => ({
    ...item,
    hospital: params.name,
  }));

  const state = controller.getState();
  const group = groupMedAllergy(items);
  const warningLength = group.warning.length;
  const allergyLength = group.allergy.length;
  let tab = state.allergyTab;

  if (!tab && allergyLength) {
    tab = "Allergy";
  } else if (!tab && warningLength) {
    tab = "Warning";
  } else if (tab === "Allergy" && !allergyLength) {
    tab = "Warning";
  } else if (tab === "Warning" && !warningLength) {
    tab = "Allergy";
  }

  console.log(
    "tab----------",
    tab,
    state.allergyTab,
    params,
    group.warning.length,
    group.allergy.length
  );

  controller.setState({
    medAllergyList: items,
    firstLoadingAllergy: true,
    medAllergyGroup: group,
    allergyTab: tab,
  });
};

export const SelectedImplant: Handler = (controller, params) => {
  if (params.item) {
    controller.setState({ selectedImplant: params.item });
  }
};

export const HandleChangeAllergyTab: Handler = (controller, params) => {
  if (params.reset) {
    const state = controller.getState();
    const group = state.medAllergyGroup;
    let tab: AllergyTab | undefined;

    if (group?.allergy.length) {
      tab = "Allergy";
    } else if (group?.warning.length) {
      tab = "Warning";
    }

    controller.setState({ allergyTab: tab });
  } else {
    controller.setState({ allergyTab: params.tab });
  }
};

// Utils
const groupMedAllergy = (list: any[]) => {
  const [allergy, warning] = (list || []).reduce(
    (result, item) => {
      const typeSplit: string = item.type.split("-");
      let type: string = typeSplit[0];

      const index = { allergy: 0, warning: 1 }[type];

      result[index ?? 2].push(item);
      return result;
    },
    [[], [], []] as any[][]
  );

  return { allergy, warning };
};
/* -------------------------- END Allergy ------------------------- */

/* ---------------------------------------------------------------- */
/*                            API implant                           */
/* ---------------------------------------------------------------- */
export const getImplantData: Handler = async (controller, params) => {
  const hospitalName = params.hospitalName;

  const [response, error] = await V3GetImplant.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
  });

  if (error) {
    controller.setState({
      implantList: [],
    });
  } else {
    const resByHospital = response?.map((item: any) => {
      return { ...item, hospital: hospitalName || "" };
    });
    controller.setState({
      implantList: resByHospital || [],
    });
  }
};

/* -------------------------- END Implant ------------------------- */
